<template>
  <div style="background-color: #ebf0ff">
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <div style="background-color: #ebf0ff">
      <v-layout wrap justify-center pt-5 pb-10>
        <v-flex xs12 sm12 md10>
          <v-layout wrap justify-start>
            <v-flex text-left pa-1 px-2 pb-2>
              <span style="font-family: sedanRegular; font-size: 18px">Block Crawler - Block level</span>
            </v-flex>
          </v-layout>
          <v-tabs v-model="tabValue" color="#7CB9E8" dark :show-arrows="true" active-class="activ" class="nonactiv"
            background-color="#ebf0ff" style="font-family: sedanRegular">
            <v-tab style="
                  color: #000;
                  text-transform: none;
                  font-family: sedanRegular;
                " dark href="#tab-1">Block Chain</v-tab>
            <v-tab style="
                  color: #000;
                  text-transform: none;
                  font-family: sedanRegular;
                " dark href="#tab-2">Mempool</v-tab>
            <v-tab-item value="tab-1">
              <v-card flat style="background-color: #ebf0ff">
                <BlockChain @stepper="winStepper" :asset="asset" />
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-card flat style="background-color: #ebf0ff">
                <Mempool value="tab-2" @stepper="winStepper" :asset="asset" :manage="manage" :assetIdNew="assetIdNew" />
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
  
<script>
import BlockChain from "./blockChain";
import Mempool from "./mempool";
export default {
  components: {
    BlockChain,
    Mempool,
  },
  data: () => ({
    appLoading: false,
    ServerError: false,
    tabValue: "tab-1",
    asset: {},
    manage: {},
    assetId: "",
    assetIdNew: "",
    check: [],
    subLevel: [],
    items: ["Block Chain", "Mempool"],
  }),
  beforeMount() {
    this.$store.commit("changeCurrentPage", "");
  },
  methods: {
    winStepper(item) {
      this.flag = false;
      window.scrollTo(0, 0);
      if (item.ref == "page1") {
        if (item.assetDetails) {
          this.asset = item.assetDetails;
        }
      }
      if (item.ref == "page1Tab") {
        if (item.level || item.check || item.subLevel) {
          this.tabValue = item.level;
          this.check = item.check;
          this.subLevel.push(item.subLevel);
        }
      }
      if (item.ref == "page1SubTab") {
        if (item.subLevel) {
          this.subLevel = item.subLevel;
        }
      }
      if (item.ref == "page2Tab") {
        if (item.level || item.subLevel) {
          this.tabValue = item.level;
          this.subLevel.push(item.subLevel);
        }
      }
      if (item.ref == "page2") {
        if (item.management) {
          this.manage = item.management;
        }
      }
      if (item.ref == "managementPage") {
        if (item.management || item.assetIdNew) {
          this.manage = item.management;
          this.assetIdNew = item.assetIdNew;
        }
      }
      if (item.ref == "assetGet") {
        if (item.assetIdNew) {
          this.assetIdNew = item.assetIdNew;
        }
      }
      if (item.ref == "assetGetPage2") {
        if (item.assetIdNew) {
          this.assetIdNew = item.assetIdNew;
        }
      }
      if (item.ref == "assetPage1") {
        if (item.assetDetails) {
          this.asset = item.assetDetails;
        }
      }
    },
  },
};
</script>
<style scoped>
.activ {
  color: black !important;
  font-family: robotoRegular !important;
}

.nonactiv {
  color: #acacac !important;
  font-family: robotoRegular !important;
}
</style>
  