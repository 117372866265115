import { render, staticRenderFns } from "./blockCrawler.vue?vue&type=template&id=4389bf10&scoped=true&"
import script from "./blockCrawler.vue?vue&type=script&lang=js&"
export * from "./blockCrawler.vue?vue&type=script&lang=js&"
import style0 from "./blockCrawler.vue?vue&type=style&index=0&id=4389bf10&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4389bf10",
  null
  
)

export default component.exports