<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" top :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center" style="font-size: 18px; font-family: montserratSemiBold">{{ msg
        }}</v-flex>
        <v-flex text-right>
          <v-btn :ripple="false" text @click="showSnackBar = false">
            <span style="font-size: 18px; font-family: montserratExtraBold">Ok
            </span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap pt-5>
      <v-flex xs12>
        <v-layout wrap pb-8>
          <v-spacer></v-spacer>
          <v-flex xs12 sm3>
            <!-- <v-text-field v-model="keyword" append-icon="mdi-magnify"
              style="border: 2px solid #7CB9E8; border-radius: 25px;" rounded label="Search"
              :hide-details="true"></v-text-field> -->
            <v-text-field v-model="keyword" solo dense @input="getData()" label="Search" single-line hide-details>
              <template v-slot:append>
                <v-icon @input="getData()"> mdi-magnify </v-icon>
              </template>
            </v-text-field>
          </v-flex>
        </v-layout>
        <v-data-table :headers="headers" :items="blocks" hide-default-footer class="elevation-1 .text--black"
          id="virtual-scroll-table">
          <template v-slot:[`item.create_date`]="{ item }">
            <span v-if="item.create_date">{{ item.create_date.slice(0, 10) }}
              {{ item.create_date.slice(11, 19) }}</span>
          </template>
          <template v-slot:[`item.archivalDate`]="{ item }">
            <span v-if="item.archivalDate">{{ item.archivalDate.slice(0, 10) }}</span>
          </template>
          <template v-slot:[`item.privateFileHash`]="{ item }">
            <span v-if="item.privateFileHash">{{ item.privateFileHash }}
            </span>
            <!-- <span v-if="item.privateFileHash.length > 25"> ... </span> -->
          </template>
          <template v-slot:[`item._id`]="{ item }">
            <v-icon small class="mr-2" @click="editcat(item)"> mdi-eye </v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-2>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 1">
          <v-pagination :length="pages" :total-visible="7" v-model="currentPage" color="#6AAFEE" circle></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 10,
      blocks: [],
      keyword: "",
      headers: [
        { text: "ID", value: "txnNumber", width: '70px' },
        { text: "Asset ID", value: "assetID", width: '150px' },
        { text: "Type", value: "transactionTypeCode", width: '100px' },
        { text: "User ID", value: "ownerUid.userID", width: '120px' },
        { text: "Submitted at  ", value: "create_date", width: '180px' },
        { text: "Archive-Date", value: "archivalDate", width: '160px' },
        { text: "Private-Hash", value: "privateFileHash", width: '110px' },
        // { text: "Phone", value: "phone" },
        // { text: "Email", value: "email" },
        //   { text: "Actions", value: "_id", sortable: false },
      ],
    };
  },
  watch: {
    currentPage() {
      // this.$store.commit("changeCurrentPage", this.currentPage);
      this.getData();
    },
    keyword() {
      this.currentPage = 1
      this.getData();
    }
  },
  mounted() {
    // if (this.$store.state.currentPage) {
    //   this.currentPage = this.$store.state.currentPage;
    //   this.getData();
    // } else 
    this.getData();
  },
  methods: {
    getSearch() {
      axios({
        url: "/mempool/transactions/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.blocks = response.data.data
            this.appLoading = false;
          } else {
            this.showsnackbar = false;
            this.blocks = [];
            this.msg = response.data.msg;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/mempool/transactions/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
          keyword: this.keyword
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.blocks = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.v-data-table-header {
  background-color: #c3e5ff;
  font-size: 20px;
  color: black;
}

.v-data-table .v-data-table-header tr th {
  font-size: 15px !important;
  color: black;
  font-family: montserratMedium;
}

#virtual-scroll-table {
  max-width: 1200px;
  overflow: auto;
}
</style>