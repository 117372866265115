<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" top :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center" style="font-size: 18px; font-family: montserratSemiBold">{{ msg
        }}</v-flex>
        <v-flex text-right>
          <v-btn :ripple="false" text @click="showSnackBar = false">
            <span style="font-size: 18px; font-family: montserratExtraBold">Ok
            </span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap pt-5>
      <v-flex>
        <v-data-table :headers="headers" :items="blocks" hide-default-footer @click:row="info"
          class="elevation-1 .text--black">
          <template v-slot:[`item.blockWrittenDate`]="{ item }">
            <span v-if="item.blockWrittenDate">{{ item.blockWrittenDate.slice(0, 10) }}
              {{ item.blockWrittenDate.slice(11, 19) }}</span>
          </template>
          <template v-slot:[`item._id`]="{ item }">
            <v-icon color="#7CB9E8" class="mr-2" @click.stop="getBlock(item), getItem = item">
              mdi-download
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-2>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 1">
          <v-pagination :length="pages" :total-visible="7" v-model="currentPage" color="#6AAFEE" circle></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="dialog" fullscreen>
      <v-card width="100%" style="background-color: white; width:100%;">
        <!-- <v-toolbar dense color="#7CB9E8" fixed flat>
          <v-spacer></v-spacer>
          <v-btn right icon dark @click="(dialog = false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar> -->
        <v-card-title class="text-h5 grey lighten-2" style="position: sticky;
                      top: 0;
                      z-index: 999;">
          Genesis Block
          <v-spacer></v-spacer>
          <v-btn right icon color="#000" @click="(dialog = false)">
            <v-icon size="30px">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div style="width:100%; overflow:auto;">

          <v-card-text style="background-color: white;">
            <!-- <v-img :src="baseURL + '/genesisBlock/view'"></v-img> -->
            <v-layout wrap py-5 style="background-color: white;">
              <v-flex text-left>
                <pre style="color:black">{{ file }}</pre>
                <!-- <span style="font-family: sedanRegular; font-size: 18px; color:black" >{{ file }}</span> -->
              </v-flex>
            </v-layout>
          </v-card-text>
        </div>

        <v-divider></v-divider>
        <v-card-actions style="padding-top:50px">
          <v-footer fixed class="text-h5 grey lighten-2">
            <v-spacer></v-spacer>
            <v-btn outlined color="#7CB9E8" text @click="(dialog = false)">
              <span style="font-family: montserratSemiBold; font-size:18px"> Ok</span>
            </v-btn>
          </v-footer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog1" fullscreen>
      <v-card width="100%" style="background-color: white; width:100%;">
        <!-- <v-toolbar dense color="#7CB9E8" fixed flat style="position: sticky;
            top: 0;
            z-index: 999;">
          <v-spacer></v-spacer>
          <v-btn right icon dark @click="(dialog1 = false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar> -->
        <v-card-title style="position: sticky;
                      top: 0;
                      z-index: 999;" class="text-h5 grey lighten-2" v-if="getItem.blockNumber == 0">
          Genesis Block
          <v-spacer></v-spacer>
          <v-btn right icon color="#000" @click="(dialog1 = false)">
            <v-icon size="30px">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else style="position: sticky;
                      top: 0;
                      z-index: 999;">
          Block {{ getItem.blockNumber }}
          <v-spacer></v-spacer>
          <v-btn right icon color="#000" @click="(dialog1 = false)">
            <v-icon size="30px">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div style="width:100%; overflow:auto">

          <v-card-text style="background-color: white;">
            <!-- <v-img :src="baseURL + '/genesisBlock/view'"></v-img> -->
            <v-layout wrap py-5 style="background-color: white;">
              <v-flex text-left>
                <pre style="color:black">{{ block }}</pre>
                <!-- <span style="font-family: sedanRegular; font-size: 18px; color:black" >{{ file }}</span> -->
              </v-flex>
            </v-layout>
          </v-card-text>
        </div>

        <v-divider></v-divider>
        <v-card-actions style="padding-top:50px">
          <v-footer fixed class="text-h5 grey lighten-2">
            <v-spacer></v-spacer>
            <v-btn outlined color="#7CB9E8" text @click="(dialog1 = false)">
              <span style="font-family: montserratSemiBold; font-size:18px"> Ok</span>
            </v-btn>
          </v-footer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 10,
      blocks: [],
      block: [],
      file: "",
      getItem: "",
      dialog: false,
      dialog1: false,
      headers: [
        { text: "Blocks", value: "blockNumber" },
        { text: "Written at", value: "blockWrittenDate" },
        { text: "#Transactions ", value: "numberOfTxns" },
        // { text: "Phone", value: "phone" },
        // { text: "Email", value: "email" },
        { text: "Actions", value: "_id", sortable: false },
      ],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.getFile()
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/blocks/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.blocks = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // async getFile() {
    //   const response = await fetch('/genesisBlock/view');
    //   this.file = await response.text();
    // },
    getFile() {
      this.appLoading = true;
      axios({
        url: "/genesisBlock/view",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.file = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBlock(item) {
      this.appLoading = true;
      axios({
        url: "/block/view",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          blockId: item._id
        }
      })
        .then((response) => {
          this.appLoading = false;
          this.block = response.data;
          this.dialog1 = true
        })
        .catch((err) => {
          console.log(err);
        });
    },
    info(id) {
      if (id.blockNumber == 0) {
        this.dialog = true
      }
      else
        this.$router.push("/blockChainView?id=" + id._id + '&block=' + id.blockNumber);
    },
  },
};
</script>
<style>
.v-data-table-header {
  background-color: #c3e5ff;
  font-size: 20px;
  color: black;
}

.v-data-table .v-data-table-header tr th {
  font-size: 15px !important;
  color: black;
  font-family: montserratMedium;
}
</style>